<template>
  <div class="quant-port">

    <div class="text-h5 text--primary d-flex pa-2 font-weight-bold"> {{ formTitle }} </div>
    <!-- 상단 버튼 -->
    <v-container fluid>
        <v-row
          no-gutters
        >
          <v-col  
            v-for="j in rows"
            :key="j"
            :class="rows === 1 ? 'd-flex justify-end' : 'd-flex justify-space-between'"
          >
            <v-btn     
              v-for="k in 6/rows"
              :key="k"
              v-show="btnShow[j === 2 && k === 1 ? 4 : j*k-1]"
              text
              color="primary"
              small 
              class="font-weight-bold"
              @click="btnFnc(j === 2 && k === 1 ? 4 : j*k-1)"
            >
              <v-icon left> 
                {{ icon[j === 2 && k === 1 ? 4 : j*k-1] }} 
              </v-icon>
              {{ btnName[j === 2 && k === 1 ? 4 : j*k-1] }}
            </v-btn>        
          </v-col>
        </v-row>
      </v-container>

    <br> 

  <!-- 첫번째 열 -->
  <v-card outlined>
    <v-row align="center" justify="space-around">
      <v-col cols="12" sm="6" md="3" lg="3" xl="3">
        <v-select
          :items="port_titles"
          label="포트폴리오 제목"
          item-text="title"
          item-value="port_id"
          v-model="selectedPort"
          @change="get_my_ports()"
          class="d-flex pt-8 pl-6" 
        ></v-select> 
        <span>거래 시작일: {{ startDate }}</span>
      </v-col>
      <v-col cols="12" sm="6" md="3" lg="3" xl="3">
        <v-card v-if="btnShow[1]" flat>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="d-flex justify-start text-subtitle-1 pa-2">
                {{rule_title}}
              </v-list-item-title>
              <v-list-item-subtitle class="d-flex justify-start pa-2">
                시장: {{rule_market}}
                시총: {{rule_market_cap}}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="d-flex justify-start pa-2">
                업종:
                <span v-for="(item, index) in rule_sector" :key="index">  
                  <v-chip class="ml-2" small> {{ item }} </v-chip>    
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>  
        </v-card>
        <v-card v-if="showGlobalRebalance" flat> 
          <v-textarea
            v-model="rule_description"
            auto-grow
            disabled
            class="d-flex pl-4" 
          ></v-textarea>
        </v-card>
      </v-col>    
      <v-col cols="12" sm="6" md="3" lg="3" xl="3">            
        <v-card v-if="showYieldCurv" flat>
          <v-card-text>
            <span class="d-flex justify-start text-caption grey--text font-weight-light">월수익율</span>
          </v-card-text>              
          <v-sparkline
            :value="performance_values"
            :gradient="gradient"
            :smooth="radius || false"
            :padding="padding"
            :line-width="width"
            :stroke-linecap="lineCap"
            :gradient-direction="gradientDirection"
            :fill="fill"
            :type="type"
            :auto-line-width="autoLineWidth"
            auto-draw
          ></v-sparkline>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3" lg="3" xl="3">            
        <v-card v-if="showYieldCurv" flat>
          <GChart 
            :type="gchart_type" 
            :data="treemap_data" 
            :options="treemap_options" 
            :settings="treemap_settings" 
            class="d-flex pt-2" 
          />
        </v-card>
      </v-col>
    </v-row>
    </v-card>

    <br>

  <!-- 두번째 행 -->
  <v-card outlined class="pa-5">
    <v-row dense>
      <v-col cols="12" sm="6" md="2" lg="2" class="d-flex justify-start">
        <span class="grey--text text-subtitle-1 font-weight-bold"> 매수금 </span>
        <span class="pl-2 grey--text text-h5 font-weight-bold"> 
          {{ sum_buy_value.toLocaleString(undefined) }} 
        </span>
        <span class="grey--text text-h6 pl-1">
          {{ currency_unit }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="2" lg="2" class="d-flex justify-start">
        <span class="black--text text-subtitle-1 font-weight-bold"> 평가금 </span>
        <span class="pl-2 black--text text-h5 font-weight-bold"> 
          {{ sum_market_value.toLocaleString(undefined) }} 
        </span>
        <span class="black--text text-h6 pl-1">
          {{ currency_unit }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="2" lg="2" class="d-flex justify-start">
        <span class="black--text text-subtitle-1 font-weight-bold"> 일수익 </span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="pl-2 text--body-2" v-bind="attrs" v-on="on"> mdi-information </v-icon>
            </template>
            변동가(현재가 - 전일종가) * 잔고
          </v-tooltip>
        <span :class="isMinus(sum_day_gain)? 'pl-6 red--text text-subtitle-1 font-weight-bold' :
            'pl-6 green--text text-subtitle-1 font-weight-bold'"> 
            {{ sum_day_gain.toLocaleString(undefined) }} {{ currency_unit }} ( {{ sum_day_gain_rate.toLocaleString(undefined) }}%) 
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="2" lg="2" class="d-flex justify-start">
        <span class="black--text text-subtitle-1 font-weight-bold"> 총수익 </span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="pl-2 text--body-2" v-bind="attrs" v-on="on"> mdi-information </v-icon>
            </template>
            총평가금 - 총매수금
          </v-tooltip>
        <span :class="isMinus(sum_total_gain)? 'pl-2 red--text text-subtitle-1 font-weight-bold' :
            'pl-2 green--text text-subtitle-1 font-weight-bold'"> 
            {{ sum_total_gain.toLocaleString(undefined) }} {{ currency_unit }} ( {{ sum_total_gain_rate.toLocaleString(undefined) }}%)
        </span>        
      </v-col>
      <v-col cols="12" sm="6" md="2" lg="2" class="d-flex justify-start">
        <span class="black--text text-subtitle-1 font-weight-bold"> 누적수익 </span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="pl-2 text--body-2" v-bind="attrs" v-on="on"> mdi-information </v-icon>
            </template>
            (총평가금 + 총매도금) - (총매수금 + 총수수료 + 총세금)
          </v-tooltip>
        <span :class="isMinus(sum_cum_gain)? 'pl-2 red--text text-subtitle-1 font-weight-bold' :
            'pl-2 green--text text-subtitle-1 font-weight-bold'"> 
            {{ sum_cum_gain.toLocaleString(undefined) }} {{ currency_unit }} ( {{ sum_cum_gain_rate.toLocaleString(undefined) }}%)
        </span>
      </v-col> 
    </v-row>
  </v-card>
  <br>

  <!-- 메인테이블 -->
  <v-card outlined>
    <v-data-table  
      v-if="dialogPort"
      :headers="headers"
      :items="stocks"
      :single-expand="true"
      :expanded.sync="expanded"
      :items-per-page="1000"
      :hide-default-footer=true
      item-key="stock_code"
      show-expand
      sort-by="market_value"
      :loading="loading"
      loading-text="조회중입니다. 잠시 기다려 주세요."
    >
      <template v-slot:item.actions="{ item }">
        <!-- <v-btn icon :href=item.url target="_blank">
          <v-icon small class="mr-2">mdi-magnify</v-icon>
        </v-btn>     -->
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-plus-circle </v-icon>
        <v-icon small @click="deleteStock(item)"> mdi-delete </v-icon>
      </template>

      <template v-slot:item.stock_name="{ item }">
        <a target="_blank" :href="item.url">
          <span class="text-subtitle-1 primary--text font-weight-bold"> {{ item.stock_name }} ({{ item.stock_code }})</span><br>
        </a>      
      </template>
      <template v-slot:item.change="{ item }">      
        <span class="text-h6 font-weight-bold"> {{ item.close.toLocaleString(undefined) }}</span>
        <span :class="isMinus(item.change)? 'red--text' : 'green--text'"> {{ item.change.toLocaleString(undefined) }} </span>
        <span :class="isMinus(item.change_rate)? 'red--text text-subtitle-1 font-weight-bold' : 'green--text text-subtitle-1 font-weight-bold'"> 
          ({{ item.change_rate }}%) </span><br>      
      </template>
      <template v-slot:item.shares="{ item }">
        <span> {{ item.shares.toLocaleString(undefined) }} </span>
      </template>
      <template v-slot:item.avg_cost="{ item }">
        <span> {{ item.avg_cost.toLocaleString(undefined) }} </span>
      </template>
      <template v-slot:item.buy_value="{ item }">
        <span> {{ item.buy_value.toLocaleString(undefined) }} </span>
      </template>
      <template v-slot:item.market_value="{ item }">
        <span> {{ item.market_value.toLocaleString(undefined) }} </span>
      </template>
      <template v-slot:item.diff_ratio="{ item }">
        <v-chip
          :color="getRatioColor(item.diff_ratio)"
          dark
        >
          {{ item.diff_ratio }}
        </v-chip>
      </template> 
      <template v-slot:item.day_gain="{ item }">
        <span :class="isMinus(item.day_gain_rate)? 'red--text text-subtitle-1 font-weight-bold' : 'primary--text text-subtitle-1 font-weight-bold'"> 
          {{ item.day_gain_rate }}% </span><br>
        <span :class="isMinus(item.day_gain)? 'red--text' : 'primary--text'"> {{ item.day_gain.toLocaleString(undefined) }} </span>
      </template>
      <template v-slot:item.total_gain="{ item }">
        <span :class="isMinus(item.total_gain_rate)? 'red--text text-subtitle-1 font-weight-bold' : 'primary--text text-subtitle-1 font-weight-bold'"> 
          {{ item.total_gain_rate }}% </span><br>
        <span :class="isMinus(item.total_gain)? 'red--text' : 'primary--text'"> {{ item.total_gain.toLocaleString(undefined) }} </span>
      </template>
      <template v-slot:item.cum_gain="{ item }">
        <span :class="isMinus(item.cum_gain_rate)? 'red--text text-subtitle-1 font-weight-bold' : 'primary--text text-subtitle-1 font-weight-bold'"> 
          {{ item.cum_gain_rate }}% </span><br>
        <span :class="isMinus(item.cum_gain)? 'red--text' : 'primary--text'"> {{ item.cum_gain.toLocaleString(undefined) }} </span>
      </template>
      <template v-slot:item.market_cap="{ item }">
        <span> {{ item.market_cap.toLocaleString(undefined) }} </span>
      </template>
      <template v-slot:item.volume="{ item }">
        <span> {{ item.volume.toLocaleString(undefined) }} </span>
      </template>

      <!-- 확장테이블 -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">

        <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">거래구분</th>
                  <th class="text-center">거래일</th>
                  <th class="text-center">매매수량</th>
                  <th class="text-center">매매가</th>
                  <th class="text-center">매매금액</th>
                  <th class="text-center">수익</th>
                  <th class="text-center">수수료</th>
                  <th class="text-center">세금</th>                
                  <th class="text-center">증권사</th>
                  <th class="text-center">메모</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="trade in item.trades" :key="trade.trade_id">
                  <td>
                    <template>
                      <v-chip
                        :color="getColor(trade.trade_type)"
                        dark
                        outlined
                      >
                        {{ trade.trade_type }}
                      </v-chip>
                    </template>                   
                  </td>
                  <td>{{ trade.date }}</td>
                  <td>{{ trade.shares.toLocaleString(undefined) }}</td>
                  <td>{{ trade.cost.toLocaleString(undefined) }}</td>
                  <td>{{ trade.amount.toLocaleString(undefined) }}</td>
                  <!-- <td>{{ trade.balance.toLocaleString() }}</td>
                  <td>{{ trade.avg_cost.toLocaleString() }}</td> -->
                  <td> 
                    <span v-if="trade.trade_type === '매도'"
                      :class="isMinus(trade.gain_rate)? 'red--text text-subtitle-1 font-weight-bold' : 'primary--text text-subtitle-1 font-weight-bold'">
                      {{ trade.gain_rate }}%</span><br>
                    <span v-if="trade.trade_type === '매도'"
                      :class="isMinus(trade.gain)? 'red--text' : 'primary--text'">
                      {{ trade.gain.toLocaleString(undefined) }}</span>
                  </td>                        
                  <td>{{ trade.trade_fee.toLocaleString(undefined) }}</td>
                  <td>{{ trade.tax.toLocaleString(undefined) }}</td>
                  <td>{{ trade.broker }}</td>
                  <td>{{ trade.memo }}</td>             
                  <td>
                    <v-icon small @click="editTrade(item, trade)"> mdi-pencil </v-icon> 
                    <v-icon small @click="deleteTrade(item, trade)"> mdi-delete </v-icon>   
                  </td>                                
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>      
    </v-data-table>   
    </v-card>

  <br><br>
  
  <v-card 
    v-if="dialogPort"
    flat
  >
  <v-list-item two-line>
    <v-list-item-content>
      <v-list-item-title class="d-flex justify-start">
        * 평가금액 및 일수익율은 NAVER 현재가 기준입니다.
      </v-list-item-title>
      <v-list-item-subtitle class="d-flex justify-start">
        * 목표비중은 백테스트 또는 자산배분전략을 통해 생성한 포트폴리오만 해당되며,
          동적자산 배분전략인 경우 매일 업데이트됩니다. 각 전략별 리밸런싱 주기에 맞춰 자산 리밸런싱을 하시기 바랍니다. 
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
  </v-card>

  
    <!-- 포트폴리오 추가 다이알로그-->
    <v-dialog 
        v-model="portAddDialogShow"
        persistent
        max-width="600px" 
    >
      <v-card class="pa-6">
        <v-text-field                 
          v-model="port_title"
          label="제목*" 
          hint="포트폴리오 제목을 입력하세요" 
          persistent-hint
          required
        > 
        </v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed outlined @click="portAddDialogShow=false">취소</v-btn>
          <v-btn color="primary" @click="create_my_ports">확인</v-btn>
        </v-card-actions>     
      </v-card>
    </v-dialog>

    <!-- 종목 추가 다이알로그-->
    <v-dialog 
      v-model="stockAddDialogShow"
      width="auto" 
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <template>
        <v-card>
          <v-card-title>
            종목추가
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="검색"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            v-model="selectedStock"                  
            :headers="stockHeaders"
            :items="codes"
            :search="search"    
            :single-select="true"            
            item-key="stock_code"
            show-select
            hide-default-footer
          ></v-data-table>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn depressed outlined @click="stockAddDialogShow=false"> 취소 </v-btn>
            <v-btn color="primary" @click="addStock" > 추가 </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    
    <!-- 거래내역 입력/수정 다이알로그-->
    <v-dialog
      v-model="dialog"
      max-width="400px"
    >          
      <v-card>            
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-title>
          <span class="text-h6">{{ selectedTile }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-radio-group v-model="editedItem.trade_type" row>
                <v-radio label="매수" value="매수"></v-radio>
                <v-radio label="매도" value="매도"></v-radio>
              </v-radio-group>
            </v-row>
            <v-row>
              <v-text-field
                v-model="editedItem.broker"
                label="증권사"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="editedItem.date"
                label="매매일"
                type="date"
                required
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="editedItem.cost"
                label="매매가"
                required
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="editedItem.shares"
                label="매매량"
                required
              ></v-text-field>          
            </v-row>
            <v-row>
              <span
                class="font-weight-bold"
              >
              매매금액: {{(parseInt(editedItem.shares)*parseFloat(editedItem.cost)).toLocaleString(undefined)}}
              </span>
              <br><br>
            </v-row>
            <v-row>
              <v-text-field
                v-model="editedItem.fee_rate"
                label="수수료(%) * 증권사 거래수수료를 입력하세요."
                hint="매도시 증권거래세(0.20%)는 자동계산됩니다."
                persistent-hint
                required
              ></v-text-field>          
            </v-row>
            <v-row>
              <v-text-field
                v-model="editedItem.memo"
                label="투자노트"
                hint="매매 사유, 손절, 익절 목표 등을 관리하세요."
                persistent-hint
                required
              ></v-text-field>          
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn depressed outlined @click="close"> 취소 </v-btn>
          <v-btn color="primary" @click="save_trade" > 저장 </v-btn>
        </v-card-actions>            
      </v-form>
      </v-card>
    </v-dialog>
    
    <!-- 삭제 다이알로그-->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">삭제 하겠습니까?</v-card-title>
        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn depressed outlined @click="closeDelete">취소</v-btn>
          <v-btn color="primary" @click="deleteStockConfirm">확인</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 거래내역 삭제 다이알로그-->
    <v-dialog v-model="dialogTradeDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">삭제 하겠습니까?</v-card-title>
        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn depressed outlined @click="closeTradeDelete">취소</v-btn>
          <v-btn color="primary" @click="deleteTradeConfirm">확인</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 포트폴리오 삭세 다이알로그-->
    <v-dialog v-model="dialogPortDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">{{ selectedPortTitle }} 포트를 삭제 하겠습니까?</v-card-title>
        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn depressed outlined @click="dialogPortDelete=false">취소</v-btn>
          <v-btn color="primary" @click="deletePortConfirm">확인</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 수익율/투자비중 차트 다이알로그-->
    <v-dialog 
      v-model="chartDialogShow"
      width="900" 
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">수익율 차트 </span>
          <span class="text-subtitle-2"> (월말 잔고 기준)</span>
        </v-card-title>
        <template>
          <div class="container">
            <line-chart
              :chartData="yield_datacollection"
              :options="line_chart_options"
              :height="200" 
            />
          </div> 
        </template>

        <v-card-title>
          <span class="text-h6">배분율 차트 </span>
          <span class="text-subtitle-2"> (월말 잔고 기준)</span>
        </v-card-title>      
        <template>
          <div class="container">
            <bar-chart
              :chartData="ratio_datacollection"
              :options="bar_chart_options"
              :height="200" 
            />
          </div> 
        </template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="chartDialogShow=false"> 닫기 </v-btn>
        </v-card-actions>            
    </v-card>
  </v-dialog>



  <!-- 알림 메세지 -->
  <v-snackbar
    v-model="snackbar"
    :vertical="vertical"
    :color="snackColor"          
  >
    {{ snackText }}

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        닫기
      </v-btn>
    </template>
  </v-snackbar>

</div>
</template>

<script>
  import axios from 'axios';
  import {mapState, mapActions, mapMutations} from 'vuex';
  import LineChart from '../components/LineChart.js';
  import BarChart from '../components/BarChart.js';
  import { GChart } from 'vue-google-charts/legacy';

  export default {    
    components: { LineChart, BarChart, GChart },
    data () {
      return {
        btnName: [
          '',
          '종목 리밸런싱',
          '포트폴리오 추가',
          '종목 추가',
          '포트폴리오 삭제',
          '수익율 차트',
        ],
        icon: [
          '',
          'mdi-chart-arc',
          'mdi-plus',
          'mdi-plus',
          'mdi-minus',
          'mdi-finance',
        ],

        btnShow: [false, true, true, true, true, true],
        dialog: false,
        dialogDelete: false,
        dialogPort: false,
        dialogPortDelete: false,
        dialogTradeDelete: false,
        valid: true,
        portAddDialogShow: false,
        stockAddDialogShow: false,
        chartDialogShow: false,
        showRebalance: false,
        showGlobalRebalance: false,
        loading: false,
        showYieldCurv: false,

        port_title: null,
        port_type: null,

        tradeTAX: 0.20,  // 증권거래세
        sum_market_value: 0,
        sum_buy_value: 0,
        sum_day_gain: 0,
        sum_day_gain_rate: 0,        
        sum_total_gain: 0,
        sum_total_gain_rate: 0,                
        sum_cum_gain: 0,
        sum_cum_gain_rate: 0,
        sum_buy_amount: 0,

        flag: 'INSERT',
        currency_unit: '원',
        startDate: null,

        width: 2,
        radius: 10,
        padding: 8,
        lineCap: 'round',
        gradient: ['#1feaea','#ffd200', '#f72047'],
        performance_values: [],
        gradientDirection: 'top',
        fill: false,
        type: 'trend',
        autoLineWidth: false,

        headers: [
          { text: '종목', align: 'start', value: 'stock_name'},
          { text: '가격(원,USD)/변동/변동율', value: 'change', align: 'end' },
          { text: '보유수량', value: 'shares', align: 'end' },
          { text: '평단가', value: 'avg_cost' , align: 'end' },
          { text: '매수금', value: 'buy_value' , align: 'end' },
          { text: '평가금', value: 'market_value' , align: 'end' },
          { text: '평가금비중(%)', value: 'market_value_ratio' , align: 'end' },
          { text: '목표비중(%)', value: 'port_ratio' , align: 'end' },
          { text: '비중차이(%)', value: 'diff_ratio' , align: 'end' },
          { text: '일수익', value: 'day_gain' , align: 'end' },          
          { text: '총수익', value: 'total_gain' , align: 'end' },
          { text: '누적수익', value: 'cum_gain' , align: 'end' },
          { text: '시총(억원/$B)', value: 'market_cap' , align: 'end' },
          { text: '거래량', value: 'volume' , align: 'end' },
          { text: '신고가율', value: 'new_high_rate' , align: 'end' },
          { text: '신저가율', value: 'new_low_rate' , align: 'end' },
          { text: '거래횟수', value: 'lots' , align: 'end' },            
          { text: 'Actions', value: 'actions', sortable: false },
        ],

        stockHeaders: [
          { text: '종목코드', value: 'stock_code', align: 'center' },
          { text: '종목명', value: 'stock_name', align: 'center' },
          { text: '시장', value: 'market', align: 'center' },
        ],
        search: '',
        selectedStock: [],

        selectedTile: '',
        selectedPortTitle: null,
        selectedPort: '0',
        selectedStockCode: '',
        port_titles: [],
        rule_id: null,
        rule_title: null,
        rule_market: null,
        rule_market_cap: null,
        rule_sector: null,
        rule_description: null,
        codes: [],
        stocks: [],
        trades: [],
        expanded: [],
        editedIndex: -1,
        editedTradeIndex: -1,
        editedItem: {
          trade_id: 0,
          trade_type: '',
          broker: '',
          date: '',
          cost: '',
          shares: '',
          amount: '',
          balance: '',
          avg_cost: '',
          fee_rate: 0.2,
          trade_fee: '',
          tax: '',
          gain: '',
          gain_rate: '',
          memo: '',
        },
        defaultItem: {
          trade_id: 0,
          trade_type: '',
          broker: '',
          date: '',
          cost: '',
          shares: '',
          balance: '',
          avg_cost: '',
          fee_rate: 0.2,
          trade_fee: '',
          tax: '',
          gain: '',
          gain_rate: '',
          memo: '',
        },     
        
        yield_datacollection: null,
        ratio_datacollection: null,
        line_chart_options: {
          title: {
            display: false,
            text: '수익율 차트',
          },
          scales: {
            xAxes: [{
                gridLines: { drawOnChartArea: false }
            }],
            yAxes: [{
                gridLines: { drawOnChartArea: true }
            }]
          },
          tooltips: {
            axis: 'xy', 
            mode: 'nearest',
            intersect: false
          },
        },

        bar_chart_options: {
          scales: {
            xAxes: [
                {
                  stacked: true,
                  // gridLines: { display: true }
                }
              ],
              yAxes: [
                {
                  stacked: true
                }
              ]
          },
          legend: { display: false }
        },    

        gchart_type: 'TreeMap',
        treemap_data: null,
        treemap_options: {
          minColor: '#f00',
          midColor: '#ddd',
          maxColor: '#0d0',
          headerHeight: 30,
          fontColor: 'black',
          showScale: false,
        },
        treemap_settings: {
          packages: ['treemap'],
        },

        snackbar: false,
        vertical: true,
        snackColor: 'success',
        snackText: "저장되었습니다.",
      }
    },
        
    watch: {
        dialog (val) {
            val || this.close()
        },
        dialogDelete (val) {
            val || this.closeDelete()
        },
        dialogTradeDelete (val) {
            val || this.closeTradeDelete()
        },
        $route (val) {
          this.port_type = this.$route.path.split('/')[1];  
          this.currency_unit = this.port_type == 'domestic' ? '원' : 'USD';           
          if (this.isLogin) this.get_my_port_list(); 
          else this.$router.push('/');
        },
    },

    computed: {
      ...mapState(['isLogin', 'debug', 'user']),    
      formTitle () {
        return this.$route.path.split('/')[1] === 'domestic' ? '나의 국내주식 포트폴리오' : '나의 글로벌자산 포트폴리오'
      }, 
      rows() {
        const { xs } = this.$vuetify.breakpoint
        return xs ? 3 : 1
      },  
    },

    created() {
      this.port_type = this.$route.path.split('/')[1];      
      this.currency_unit = this.port_type == 'domestic' ? '원' : 'USD';         
      if (this.isLogin) this.get_my_port_list(); 
      else this.$router.push('/');
    },

    methods: {
      ...mapMutations(['setMarket', 'setRule', 'setFilter', 'setTest', 'setRoute', 'setPortID']),
      
        btnFnc(idx) {
          if ( idx === 1 ) this.rebalance();
          else if ( idx === 2 ) this.portAddDialogShow=true;
          else if ( idx === 3 ) this.searchStock();
          else if ( idx === 4 ) this.dialogPortDelete=true;
          else if ( idx === 5 ) this.yieldChart(true);
        },

        searchStock() {
          this.stockAddDialogShow = true;
          var vm = this;
          const req_data = {user: this.user};
          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};   
          axios.post('/api/quant/port/stock/'+this.port_type+'/', req_data, {headers})
            .then(function(res) {
              vm.codes = res.data;
            })
            .catch(function (err) {
              vm.snackbar = true;
              vm.snackColor = 'error';
              vm.snackText = err;            
            });
        },

        addStock() {
          var vm = this;
          
          var stock_code = this.selectedStock[0]['stock_code'];
          var stock_name = this.selectedStock[0]['stock_name'];
          var price = this.selectedStock[0]['close'];
          var change = this.selectedStock[0]['change'];
          var change_rate = this.selectedStock[0]['change_rate'];

          const req_data = {'user': this.user, 'port_id': this.selectedPort, 'stock_code': stock_code};
          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
          axios.post('/api/quant/port/add/stock/'+this.port_type+'/', req_data, {headers})
            .then(function(res) {
              vm.stockAddDialogShow = false;
              vm.snackbar = true;
              vm.snackColor = 'success';
              vm.snackText = '추가 되었습니다.';
              vm.get_my_ports();
            })
            .catch(function (err) {
              vm.snackbar = true;
              vm.snackColor = 'error';
              vm.snackText = err;            
            });
          
        },

        create_my_ports() {
          var vm = this;
          
          // port_id 계산
          const new_port_id = parseInt(this.port_titles[0]['port_id']) + 1;                   
          const req_data = {user: this.user, title: this.port_title, port_id: new_port_id};

          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
          axios.post('/api/quant/port/add/'+this.port_type+'/', req_data, {headers})
            .then(function(res) {
              vm.port_titles.unshift({port_id: new_port_id, title: vm.port_title});
              vm.selectedPort = vm.port_titles[0]['port_id'];
              vm.portAddDialogShow = false;
              vm.snackbar = true;
              vm.snackColor = 'success';
              vm.snackText = '추가 되었습니다.';
              vm.get_my_ports();
            })
            .catch(function (err) {
              vm.snackbar = true;
              vm.snackColor = 'error';
              vm.snackText = err;            
            });
        },

        get_my_port_list() {
            var vm = this;
            
            const req_data = {user: this.user}
            const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
            axios.post('/api/quant/port/list/'+this.port_type+'/', req_data, {headers})
              .then(function(res) {
                vm.port_titles = res.data;  
                if (vm.port_titles.length == 0) {
                    vm.dialogPort = false;
                } else {
                    vm.dialogPort = true;                     
                    vm.selectedPort = vm.port_titles[0]['port_id'];                                           
                    vm.get_my_ports();
                }                
              })
              .catch(function (err) {
                vm.snackbar = true;
                vm.snackColor = 'error';
                vm.snackText = err;            
              });
        },

        get_my_ports() {
          this.loading = true;
          this.port_titles.forEach(element => {
            if (element['port_id'] == this.selectedPort) {
              this.selectedPortTitle = element['title'];
              this.startDate = element['start_date'];
            }
          });          
          var vm = this;
          
          const req_data = {user: this.user}
          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
          axios.post('/api/quant/port/'+this.port_type+'/'+this.selectedPort+'/', req_data, {headers})
            .then(function(res) {              
              vm.stocks = res.data['stock_list'];                
              vm.rule_id = res.data['rule_info']['rule_id'];
              vm.rule_title = res.data['rule_info']['rule_title'];
              vm.rule_market = res.data['rule_info']['market'];
              vm.rule_market_cap = res.data['rule_info']['market_cap'];
              vm.rule_sector = res.data['rule_info']['sector'];
              vm.rule_description = res.data['rule_info']['rule_description'];
              vm.showRebalanceBtn(vm.rule_id);
              vm.updateSumRow();
              vm.loading = false;
              vm.yieldChart(false);
            })
            .catch(function (err) {
              vm.snackbar = true;
              vm.snackColor = 'error';
              vm.snackText = err;            
            });
        },

        rebalance() {          
          var vm = this;

          const req_data = {user: this.user}
          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
          axios.post('/api/quant/rule/'+this.rule_id+'/', req_data, {headers})
            .then(function(res) {
              var result = res.data;
              vm.setPortID({'port_id': vm.selectedPort});
              vm.setTest({'title': vm.rule_title});
              vm.setMarket({'market': result['market'], 'market_cap': result['market_cap'], 'sector': result['sector']});
              vm.setFilter({'filters': result['filters']});
              vm.setRule({'rules': result['rules']});
              vm.setRoute({'route':'rebalance'});
              vm.$router.push('/search');
            })
            .catch(function (err) {
              vm.snackbar = true;
              vm.snackColor = 'error';
              vm.snackText = err;            
            });
        },

        yieldChart(showFlag) {
          var vm = this;
          this.performance_values = [];
          
          const req_data = {user: this.user}
          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
          axios.post('/api/quant/port/yield/'+this.port_type+'/'+this.selectedPort+'/', req_data, {headers})
            .then(function(res) {              
              var chart_data = res.data; 
              vm.yield_datacollection = { labels: chart_data['label'], datasets: chart_data['yield_data']};
              vm.ratio_datacollection = { labels: chart_data['label'], datasets: chart_data['ratio_data']};                
              vm.chartDialogShow = showFlag;
              vm.showYieldCurv = true;
              const idx = chart_data['yield_data'].length-1;
              vm.performance_values = chart_data['yield_data'][idx]['data'];
            })
            .catch(function (err) {
              vm.showYieldCurv = false;
              vm.snackbar = showFlag;
              vm.snackColor = 'warning';
              vm.snackText = '거래 이력이 없습니다.';            
            });
        },

        put_my_trade(trade) {
          var vm = this;
          
          const req_data = {'user': this.user, 'stock_code': this.selectedStockCode, 'trade': trade};
          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
          axios.post('/api/quant/port/update/'+this.port_type+'/'+vm.selectedPort+'/', req_data, {headers})
              .then(function(res) {
                if (vm.flag == 'INSERT') {
                  vm.stocks[vm.editedIndex].trades.push(trade);
                } else { // UPDATE
                  Object.assign(vm.stocks[vm.editedIndex].trades[vm.editedTradeIndex], trade)
                } 
                vm.updateStockRow();
                vm.updateSumRow();               
                vm.close();        

                vm.saveDialog = false;
                vm.snackbar = true;
                 vm.snackColor = 'success';
                vm.snackText = "저장되었습니다." 
              })
              .catch(function (err) {
                vm.snackbar = true;
                vm.snackColor = 'error';
                vm.snackText = err;            
              });
        },

        // setItem({stock}) {
        //   this.selectedStockCode = stock.stock_code;
        //   this.editedIndex = this.stocks.indexOf(stock);
        // },

        editItem (stock) {
            this.selectedTile = stock['stock_name'];       
            this.selectedStockCode = stock['stock_code'];
            this.editedIndex = this.stocks.indexOf(stock);
            this.editedItem.cost = stock['close'];
            // this.editedItem = Object.assign({}, stock.trades);
            this.flag = 'INSERT';
            this.dialog = true;
        },

        deleteStock (stock) {
            this.title = stock['title'];
            this.editedIndex = this.stocks.indexOf(stock);
            this.editedItem = Object.assign({}, stock);
            this.selectedStockCode = stock['stock_code'];
            this.dialogDelete = true;            
        },

        deleteStockConfirm () {
          var vm = this
          
          const req_data = {user: this.user}
          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
          axios.post('/api/quant/port/delete/'+this.port_type+'/'+this.selectedPort+'/'+this.selectedStockCode+'/', req_data, {headers})
            .then(function(res) {
                vm.stocks.splice(vm.editedIndex, 1)
                vm.closeDelete()
            })
            .catch(function (err) {
              vm.snackbar = true;
              vm.snackColor = 'error';
              vm.snackText = err;            
            });            
        },

        editTrade(stock, trade) {          
          this.editedIndex = this.stocks.indexOf(stock);
          this.selectedStockCode = this.stocks[this.editedIndex].stock_code;
          this.editedTradeIndex = this.stocks[this.editedIndex].trades.indexOf(trade);  
          this.editedItem = Object.assign({}, trade);
          this.flag = 'UPDATE';
          this.dialog = true;
        },

        deleteTrade (stock, trade) {
          this.editedIndex = this.stocks.indexOf(stock);
          this.editedTradeIndex = this.stocks[this.editedIndex].trades.indexOf(trade);
          this.editedItem = Object.assign({}, trade);
          this.dialogTradeDelete = true;            
        },

        deleteTradeConfirm () {
          var vm = this;              
          const stock_code = this.stocks[this.editedIndex].stock_code;
          const trade_id = this.stocks[this.editedIndex].trades[this.editedTradeIndex].trade_id;
          const req_data = {'user': this.user, 'trade_id': trade_id};
          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
          axios.post('/api/quant/trade/delete/'+this.port_type+'/'+this.selectedPort+'/'+stock_code+'/', req_data, {headers})
            .then(function(res) {
              // const deletedStockIndex = vm.stocks.indexOf(stock);
              // const deletedTradeIndex = vm.stocks[deletedStockIndex].trades.indexOf(trade);     
              vm.stocks[vm.editedIndex].trades.splice(vm.editedTradeIndex, 1);              
              vm.updateStockRow();
              vm.updateSumRow();
              vm.dialogTradeDelete = false;  
              vm.snackbar = true;
              vm.snackColor = 'success';
              vm.snackText = "삭제되었습니다." 
            })
            .catch(function (err) {
              vm.snackbar = true;
              vm.snackColor = 'error';
              vm.snackText = err;            
            });            
        },

        deletePortConfirm () {
          var vm = this
          
          const req_data = {user: this.user}
          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
          axios.post('/api/quant/port/delete/'+this.port_type+'/'+this.selectedPort+'/', req_data, {headers})
              .then(function(res) {
                  vm.dialogPortDelete = false
                  vm.saveDialog = false;
                  vm.snackbar = true;
                  vm.snackColor = 'success';
                  vm.snackText = "삭제되었습니다." 
                  vm.get_my_port_list();
              })
              .catch(function (err) {
                  vm.snackbar = true;
                  vm.snackColor = 'error';
                  vm.snackText = err;            
              });            
        },

        close () {
            this.dialog = false
            this.$nextTick(() => {
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
            })
        },        
        
        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
            })
        },

        closeTradeDelete () {
            this.dialogTradeDelete = false
            this.$nextTick(() => {
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
            })
        },

        save_trade () {
          if (this.editedItem.trade_type == '' || this.editedItem.date == '' || this.editedItem.shares == '' || 
            this.editedItem.cost == '') {
            this.snackbar = true;
            this.snackColor = 'error';
            this.snackText = '매매종류, 매매일, 매매가, 매매량은 반드시 입력해야 합니다.';    
            return null;       
          } else {
            // 거래내역 추가 시 trade_id 계산 
            if (this.flag == 'INSERT') {
              if (this.stocks[this.editedIndex].trades.length > 0) {
                // 다음 trade_id 계산
                var max_id = 0;
                this.stocks[this.editedIndex].trades.forEach(element => {
                  var cur_id = parseInt(element.trade_id);              
                  if (cur_id > max_id) max_id = cur_id;  
                });              
                this.editedItem.trade_id = max_id + 1;
              } else {
                this.editedItem.trade_id = 1;              
              }
            }
          } 
            
          // 매매금액
          this.editedItem.amount = parseFloat(this.editedItem.cost) * parseFloat(this.editedItem.shares);
          // 수수료
          this.editedItem.trade_fee = (this.editedItem.amount * this.editedItem.fee_rate/100).toFixed(2);                     
            
          if (this.editedItem.trade_type == '매수') {
            this.editedItem.tax = 0;
          } else {  //매도
            // 평단가
            this.editedItem.avg_cost = parseFloat(this.stocks[this.editedIndex].avg_cost);
            // 세금
            this.editedItem.tax = (this.editedItem.amount * this.tradeTAX/100).toFixed(2);
            // 수익금 = 매도금액 - 투자금(매도수량*평단가)
            this.editedItem.gain = this.editedItem.amount - (parseFloat(this.editedItem.shares) * this.editedItem.avg_cost);
            // 수익율 = 수익금*100 / 투자금액            
            this.editedItem.gain_rate = (this.editedItem.gain*100 / 
                                        (parseFloat(this.editedItem.shares) * this.editedItem.avg_cost)).toFixed(2);
          }
          this.put_my_trade(this.editedItem);
        },


        updateStockRow() {
          // 종목 데이터 업데이트
          var buy_shares = 0; var sell_shares = 0; var cum_value = 0; var day_gain = 0; 
          var total_tax = 0; var total_fee = 0; var prv_avg_cost = 0; var prv_balance_shares = 0;
          var avg_cost = 0; var balance_shares = 0;
          var total_gain = 0; var buy_amount = 0; var lots = 0;
          // date로 정열
          this.stocks[this.editedIndex].trades.sort((a, b) => a.date.localeCompare(b.date));
          this.stocks[this.editedIndex].trades.forEach(element => {
            if (element.trade_type == '매수') {
              balance_shares += parseFloat(element.shares);
              avg_cost = (((prv_avg_cost * prv_balance_shares) + element.amount) / balance_shares).toFixed(2); 
              buy_amount += parseFloat(element.amount);
              prv_avg_cost = avg_cost; prv_balance_shares = balance_shares;
            } else {  // 매도
              balance_shares -= parseFloat(element.shares);
              cum_value += parseFloat(element.amount);
              total_tax += parseFloat(element.tax);
            }            
            total_fee += parseFloat(element.trade_fee); 
            lots += 1;
          });
              
          this.stocks[this.editedIndex].shares = balance_shares;          
          if (balance_shares <= 0) avg_cost = 0;
          this.stocks[this.editedIndex].avg_cost = avg_cost;
          this.stocks[this.editedIndex].buy_value = buy_amount;
          this.stocks[this.editedIndex].market_value = (parseFloat(this.stocks[this.editedIndex].close) * balance_shares);
          cum_value += (parseFloat(this.stocks[this.editedIndex].close) * balance_shares);
          cum_value -= (total_fee + total_tax);
          this.stocks[this.editedIndex].cum_value = cum_value;
          this.stocks[this.editedIndex].day_gain = parseFloat(this.stocks[this.editedIndex].change) * balance_shares;
          this.stocks[this.editedIndex].day_gain_rate = this.stocks[this.editedIndex].change_rate;
          this.stocks[this.editedIndex].total_gain = cum_value - buy_amount;
          this.stocks[this.editedIndex].total_gain_rate = (this.stocks[this.editedIndex].total_gain*100 / buy_amount).toFixed(2);
          this.stocks[this.editedIndex].lots = lots;
        },

        updateSumRow() {
          var sum_gain = 0; var sum_prvday_amount = 0; var sum_cost = 0;
          var avg_cost = 0; var balance_shares = 0;
          this.sum_buy_value = 0; this.sum_market_value = 0; this.sum_day_gain = 0; this.sum_total_gain = 0; this.sum_cum_gain = 0;
          this.treemap_data = [
            ['Location', 'Parent', 'Market trade volume (size)', 'Market increase/decrease (color)',],
            ['평가금비중', null, 0, 0],
          ];
          this.stocks.forEach(element => {
            avg_cost = element.avg_cost == ''? 0 : parseFloat(element.avg_cost);
            balance_shares = element.share == ''? 0 : parseFloat(element.shares);
            this.sum_buy_value +=  avg_cost * balance_shares;  // 총투자금 = 평단가 * 잔고
            sum_prvday_amount += (element.close - element.change) * element.shares;
            sum_cost += parseFloat(element.total_cost);
            this.sum_market_value += element.market_value == ''? 0 : parseFloat(element.market_value);             
            this.sum_day_gain += element.day_gain == ''? 0 : parseFloat(element.day_gain);
            this.sum_total_gain += element.total_gain == ''? 0 : parseFloat(element.total_gain);            
            this.sum_cum_gain += element.cum_gain == ''? 0 : parseFloat(element.cum_gain);
            // Google TreeMap Chart
            const map_name = this.$route.path.split('/')[1] === 'global' ? element.stock_code : element.stock_name;  
            this.treemap_data.push([map_name, '평가금비중', element.market_value_ratio, element.total_gain_rate])
          });
          // 일손익율 = 일 총손익 / (전일종가 = 종가-변동비) * 잔고 
          this.sum_day_gain_rate = (this.sum_day_gain*100 / sum_prvday_amount).toFixed(2);
          // 총손익율 =  총수익금 / 총투자금액    
          this.sum_total_gain_rate = (this.sum_total_gain*100 / this.sum_buy_value).toFixed(2);
          // 누적손익율 = 누적수익 / 총원가
          this.sum_cum_gain_rate = (this.sum_cum_gain*100 / sum_cost).toFixed(2);
        },

        isMinus (item) {
          // return item.replace(/,/g, "") < 0 ? true : false
          return item < 0 ? true : false
        },

        getColor (trade_type) {
          if (trade_type === "매도") return 'green'
          else if (trade_type === "매수") return 'orange'
          else return 'red'
        },

        getRatioColor (value) {
          if (Math.abs(value) > 0  && Math.abs(value) <=10) 
            return '#FFB74D';
          else if (Math.abs(value) > 10  && Math.abs(value) <=20)
            return '#FFA726';
          else if (Math.abs(value) > 20  && Math.abs(value) <=30)
            return '#FB8C00';
          else if (Math.abs(value) > 30  && Math.abs(value) <=40)
            return '#F57C00';
          else if (Math.abs(value) > 40  && Math.abs(value) <=50)
            return '#EF6C00';
          else if (Math.abs(value) > 50)
            return '#E65100';
          else
            return 'white';
        },

        showRebalanceBtn (rule_id) {
          if (this.port_type == 'domestic') {
            this.showGlobalRebalance = false;
            this.btnShow[1] = isNaN(rule_id) ?  false : true;
          } else {
            this.btnShow[1] = false;
            this.showGlobalRebalance = isNaN(rule_id) ?  false : true;
          }
        },

    }
  }
</script>


<style>
  .quant-port {
    padding: 10px 10px;
    margin-top: 10px;
  }  
  .v-data-table.row-height-80 td {
    height: 80px !important;
  }
</style>
